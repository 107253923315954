import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import MainLayout from '@/components/layout/MainLayout.vue'
import LoginPage from '@/components/auth/LoginPage.vue'
import ForgottenPassword from '@/components/auth/ForgottenPassword.vue'
import ResetPassword from '@/components/auth/ResetPassword.vue'
import VerifyEmail from '@/components/auth/VerifyEmail.vue'
import OrganisationTipVIew from '@/views/OrganisationTipVIew.vue'
import UserView from '@/views/UserView.vue'
import OrganisationVIew from '@/views/OrganisationView.vue'
import SettingsView from '@/views/SettingsView.vue'
import TipView from '@/views/TipView.vue'
import TipSuccess from '@/views/TipSuccess.vue'
import EmployeesView from '@/views/EmployeesView.vue'
import OrganisationTypeView from '@/views/OrganisationTypesView.vue'
import RolesCategoryTypeView from '@/views/RolesCategoryTypeView.vue'
import RatingsView from '@/views/RatingsView.vue'
import ReportsView from '@/views/ReportsView.vue'
import TransactionCost from '@/views/TransactionCost.vue'
import RoleType from '@/views/RoleTypes.vue'
import SuggestedComments from '@/views/SuggestedCommentsView.vue'
import HomePage from '@/views/HomePage.vue'
import TermsAndConditions from '@/views/TermsAndConditions.vue'
import RegisterComponent from '@/components/auth/RegisterComponent.vue'
import CommissionCost from '@/views/CommisionCost.vue'
import ReportView from '@/views/ReportView.vue'

const routes = [{
    path: '/',
    name: 'HomePage',
    component: HomePage,
    props: false,
},
{
    path: '/login',
    name: 'Login',
    component: LoginPage,
    props: false,
},
{
    path: '/forgotten-password',
    name: 'ForgottenPassword',
    component: ForgottenPassword,
    props: false,
},
{
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    props: false,
},
{
    path: '/verify',
    name: 'VerifyEmail',
    component: VerifyEmail,
    props: false,
},
{
    path: '/register',
    name: 'Register',
    component: RegisterComponent,
    props: false,
},
{
    // path: '/tip/:code_identity',
    path: '/tip',
    name: 'TipView',
    component: TipView,
    props: true,
},
{
    // path: '/tip/:code_identity',
    path: '/tip/success',
    name: 'TipSuccess',
    component: TipSuccess,
    props: true,
},
{
    path: '/terms-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
},
{
    path: '/dashboard',
    name: 'Dashboard',
    meta: { requiresAuth: true },
    component: MainLayout,
    children: [{
        path: '/dashboard',
        name: 'Dashboard',
        component: DashboardView,
        meta: { requiresAuth: true },
        props: false,
    },
    {
        path: '/organisation-tips',
        name: 'OrganisationTipVIew',
        component: OrganisationTipVIew,
        props: false,
    },
    {
        path: '/users',
        name: 'UserView',
        component: UserView,
        props: false,
    },
    {
        path: '/comments',
        name: 'SuggestedComments',
        component: SuggestedComments,
        props: false,
    },
    {
        path: '/organisations',
        name: 'OrganisationVIew',
        component: OrganisationVIew,
        props: false,
    },
    {
        path: '/employees',
        name: 'EmployeesView',
        component: EmployeesView,
        props: false,
    },
    {
        path: '/settings',
        name: 'SettingsView',
        component: SettingsView,
        props: false,
    },
    {
        path: '/organisation-types',
        name: 'OrganisationTypeView',
        component: OrganisationTypeView,
        props: false,
    },
    {
        path: '/role-category-types',
        name: 'RolesCategoryTypeView',
        component: RolesCategoryTypeView,
        props: false,
    },
    {
        path: '/ratings',
        name: 'RatingsView',
        component: RatingsView,
        props: false,
    },
    {
        path: '/report',
        name: 'ReportsView',
        component: ReportsView,
        props: false,
    },
    {
        path: '/role-type',
        name: 'RoleType',
        component: RoleType,
        props: false,
    },
    {
        path: '/transaction-cost',
        name: 'TransactionCost',
        component: TransactionCost,
        props: false,
    },
    {
        path: '/commission-cost',
        name: 'CommissionCost',
        component: CommissionCost,
        props: false,
    },
    {
        path: '/reports',
        name: 'ReportView',
        component: ReportView,
        props: false,
    },

    ]
},
]

const router = createRouter({
    mode: history,
    history: createWebHistory(),
    routes
})

export default router