<template>
  <v-dialog v-model="snackbar" width="400">
    <v-row>
      <v-col cols="1">
        <v-btn
          variant="flat"
          color="success"
          class="close-button"
          icon
          size="x-small"
          @click="closeDialog"
        >
          <v-icon size="17" class="success">mdi-close</v-icon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="11">
        <v-alert type="success">
          <template v-slot:text>
            <span class="text-justify">
              Dear Customer, you have tipped
              {{ bodyData.employeeName + " " + "Ksh." + " " + bodyData.amount }}
              at
              {{
                bodyData.employeeOrg == "ArtCaffe"
                  ? bodyData.employeeOrg
                  : "ArtCaffe" + " " + bodyData.employeeOrg
              }}. Service fee cost is {{ "Ksh." + " " + bodyData.commission }}.
              Powered by Gratify.
            </span>
          </template>
        </v-alert>
      </v-col>
    </v-row>
  </v-dialog>
</template>


<script>

export default {
  name: "FcmComponent",
  data: () => ({
    snackbar: false,
    bodyData: "",
    text: "Hello, I'm a snackbar",
  }),

  mounted() {
    const channel = new window.BroadcastChannel("sw-messages");
    channel.addEventListener("message", (payload) => {
      console.log("Message received. ", payload.data.notification);
      this.bodyData = JSON.parse(payload.data.notification.body);
      this.text = payload.data.notification.title;
      if (this.bodyData.mpesaReceiptNumber.length > 0) {
        this.snackbar = true;
		localStorage.setItem("payload", payload.data.notification.body)
		this.$router.push("/tip/success")
      } else {
        this.$store.dispatch("alert/error", this.bodyData.message);
      }
    });
  },

  methods: {
    closeDialog() {
      this.snackbar = false;
    },
  },
};
</script>
<style scoped>
.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}
</style>


