<template>
	<v-app id="inspire">
		<!-- NAVIGATION DRAWER HOLDING EMPLOYEES AND ORGANISATION FILTERS -->
		<v-navigation-drawer v-model="drawer" class="pa-1">
			<!-- org filter -->
			<v-autocomplete
				v-model="editedItem.organisation_id"
				variant="outlined"
				density="compact"
				label="Filter by Organisation"
				:items="organisations"
				item-title="organisation_name"
				item-value="id"
				@update:modelValue="getOrgEmployees"
				class="mt-1"
			></v-autocomplete>
			<!-- List of Employees -->
			<v-card class="mt-n4" elevation="0" :loading="loading">
				<v-list>
					<v-list-item
						v-for="item in employees"
						:key="item.employee_id"
						:value="item.employee_id"
						rounded="lg"
						v-model:selected="editedItem.code_identity" 
						@click="selectedEmployee(item)"
					>
					<template v-slot:title>
						{{ item.employee_name}}
					</template>
					<template v-slot:prepend>
						<v-avatar color="primary" size="30">{{ item.employee_id }}</v-avatar>
					</template>
					</v-list-item>
				</v-list>
			</v-card>
		</v-navigation-drawer>

		<!-- APPBAR WITH TABLE TO DISPLAY TIPS -->
		<v-app-bar variant="flat" elevation="0" density="compact">
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title
				class="text-h6 text-uppercase font-weight-bold text--darken-1 justify-center text-primary"
			>
				Organisation Tips
			</v-toolbar-title>
		</v-app-bar>
		<v-main>
			<v-data-table
				:headers="headers"
				:items="tips"
				:search="search"
				:custom-filter="filterText"
				class="elevation-2"
				:loading="loading"
			>
				<template v-slot:top>
					<v-dialog v-model="dialog" max-width="500px">
						<v-card>
							<v-card-title>
								<span
									class="text-h6 font-weight-bold text--darken-1 justify-center text-primary"
									>{{ formTitle }}</span
								>
							</v-card-title>

							<v-card-text>
								<v-form lazy-validation ref="form">
									<v-container>
										<v-row>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-text-field
													v-model="
														editedItem.organisation_type
													"
													label="Organisation Type Name"
													variant="outlined"
													density="compact"
													:rules="rules.required"
												></v-text-field>
											</v-col>
										</v-row>
									</v-container>
								</v-form>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="error"
									variant="elevated"
									@click="close"
								>
									Cancel
								</v-btn>
								<v-btn
									color="secondary"
									variant="elevated"
									@click="save"
									:loading="loading"
								>
									Save
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="text-h5"
								>Are you sure you want to delete this
								item?</v-card-title
							>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="blue-darken-1"
									variant="text"
									@click="closeDelete"
									>Cancel</v-btn
								>
								<v-btn
									color="blue-darken-1"
									variant="text"
									@click="deleteItemConfirm"
									>OK</v-btn
								>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</template>

				<template v-slot:[`item.created_at`]="{ item }">
					{{ formatDate(item.created_at) }}
				</template>
				<template v-slot:[`item.updated_at`]="{ item }">
					{{ formatDate(item.updated_at) }}
				</template>
				<template v-slot:[`item.tipper`]="{ item }">
					{{ item.tipper.firstname && item.tipper.lastname ? item.tipper.firstname + " " + item.tipper.lastname : item.tipper.nickname }}
				</template>
				<template v-slot:no-data> No data Available </template>
			</v-data-table>
		</v-main>
	</v-app>
</template>

<script>

import OrganisationService from "@/services/organisation_service";
import EmployeesService from "@/services/employees.service"

export default {
	components: {
		
	},
	data: () => ({
		drawer: null,
		dialog: false,
		dialogDelete: false,
		dialogQrcode: false,
		valid: false,
		search: "",
		employeeTips: [],
		organisations: [],
		employees: [],
		tips: [],
		rules: {
			select: [(v) => !!v || "An item should be selected"],
			select2: [
				(v) => v.length > 0 || "At least one item should be selected",
			],
			required: [
				(v) => !!v || "Field is required",
				(v) => (v && v.length >= 3) || "Min 3 characters",
			],
		},
		headers: [
		
			{ title: "Amount (Ksh)", value: "mpesa_results.amount" },
			{ title: "	Mpesa Receipt Number", value: "mpesa_results.mpesa_receipt_number" },
			{ title: "Tipper", value: "tipper" },
			{ title: "Comment", value: "comment" },
			{ title: "Tip Date", value: "created_at" },
		],

		loading: false,
		editedIndex: -1,
		editedItem: {
			organisation_type: "",
			organisation_id: 1,
			code_identity:'Emp No. 123456781',
		},
		defaultItem: {
			organisation_id: "",
			organisation_type: "",
		},
	}),
	computed: {
		formTitle() {
			return this.editedIndex === -1
				? "New Organisation Type"
				: "Edit Organisation Type";
		},
		user() {
			return this.$store.state.auth.user;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},

		dialogDelete(val) {
			val || this.closeDelete();
		},
	},

	created() {
		this.getEmployeeTips();
		this.getOrganisations();
		this.getOrgEmployees();
	},

	methods: {
		async validate() {
			this.valid = await this.$refs.form.validate();
		},

		// search
		filterText(value, search) {
			return (
				value != null &&
				search != null &&
				typeof value === "string" &&
				value.toLowerCase().includes(search.toLowerCase())
			);
		},

		defautEmployee(){
			return this.employees[0].code_identity;
		},

		formatDate(isoDate) {
			const date = new Date(isoDate);
			const dd = String(date.getDate()).padStart(2, "0");
			const mm = String(date.getMonth() + 1).padStart(2, "0");
			const yyyy = date.getFullYear();
			const formattedDate = `${dd}-${mm}-${yyyy}`;
			return formattedDate;
		},

		selectedEmployee(item){
			this.getEmployeeTips(item);
		},

		getEmployeeTips(item) {
			this.editedItem.code_identity = item ? item.code_identity : this.editedItem.code_identity;
			return EmployeesService.getEmployeeTips(this.editedItem.code_identity).then(
				(response) => {
					if (response.status == 200) {
						this.employeeTips = response.data.data;
						this.tips = this.employeeTips.tips.data;

						this.loading = false;
					} else {
						this.roleCategoryType = [];
						this.loading = false;
						this.$store.dispatch(
							"alert/error",
							response.data.console.error
						);
						console.log(response.data.console.error);
					}
				},
				(error) => {
					this.roleCategoryType = [];
					if (error.response.status == 401) {
						this.handleLogout();
					}
					console.log(error);
					this.$store.dispatch("alert/error", error.message);
				}
			);
		},

		// get all organisations
		getOrganisations() {
			return OrganisationService.index().then(
				(response) => {
					if (response.status == 200) {
						this.organisations = response.data.data;
						this.loading = false;
					} else {
						this.organisations = [];
						this.loading = false;
						this.$store.dispatch(
							"alert/error",
							response.data.console.error
						);
						console.log(response.data.console.error);
					}
				},
				(error) => {
					this.organisations = [];
					console.log(error);
					this.$store.dispatch("alert/error", error.message);
					if (error.response.status == 401) {
						this.handleLogout();
					}
				}
			);
		},

		getOrgEmployees() {
			this.loading = true;
			return OrganisationService.orgEmployees(
				this.editedItem.organisation_id
			).then(
				(response) => {
					if (response.status == 200) {
						this.employees = response.data.data;
						this.loading = false;
					} else {
						this.employees = [];
						this.loading = false;
						this.$store.dispatch(
							"alert/error",
							response.data.console.error
						);
						console.log(response.data.console.error);
					}
				},
				(error) => {
					this.employees = [];
					this.loading = false;
					console.log(error);
					this.$store.dispatch("alert/error", error.message);
					if (error.response.status == 401) {
						this.handleLogout();
					}
				}
			);
		},

		// Data Manipulation
		deleteItem(item) {
			this.loading = true;
			this.editedIndex = this.employeeTips.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.employeeTips.splice(this.editedIndex, 1);
			EmployeesService.delete(this.editedItem).then(
				(response) => {
					if (response.status == 200) {

						this.loading = false;
						this.close();
					} else {
						this.$store.dispatch(
							"alert/error",
							response.data.message
						);
					}
				},
				(error) => {
					if (error.response.status == 422) {
						this.$store.dispatch(
							"alert/error",
							error.response.data.message
						);
						this.loading = false;
					}
					if (error.response.status == 401) {
						this.handleLogout();
					}
					console.log(error);
					this.$store.dispatch(
						"alert/error",
						error.response.data.message
					);
					this.loading = false;
				}
			);
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				this.loading = false;
			});
		},

		handleLogout() {
			this.$store.commit('logout');
			this.$store.dispatch('resetInactivityTimeout');
			this.$store.dispatch("auth/logout");
			this.$store.dispatch('resetInactivityTimeout');
			this.$router.push("/login");
			this.$store.dispatch("alert/success", 'Logout Successfully');
		},

		editItem(item) {
			this.editedIndex = this.employeeTips.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		showQrcode(item) {
			this.editedIndex = this.employeeTips.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogQrcode = true;
		},


		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			this.validate();
			if (this.valid || this.valid.valid) {
				this.loading = true;
				// edit
				if (this.editedIndex > -1) {
					Object.assign(
						this.employeeTips[this.editedIndex],
						this.editedItem
					);
					EmployeesService.update(this.editedItem).then(
						(response) => {
							if (response.status == 200) {
								this.$store.dispatch(
									"alert/success",
									response.data.message
								);
								this.loading = false;
								this.getEmployeeTips();
								this.close();
							} else {
								this.$store.dispatch(
									"alert/error",
									"Invalid data, please check the form try again!"
								);
								this.loading = false;
							}
							(error) => {
								this.getEmployeeTips();
								if (error.response.status == 422) {
									this.$store.dispatch(
										"alert/error",
										error.response.data.message
									);
								}
								if (error.response.status == 401) {
									this.handleLogout();
								}
								this.$store.dispatch(
									"alert/error",
									error.response.data.message
								);
								this.loading = false;
							};
						}
					);
				} else {
					// Create
					EmployeesService.store(this.editedItem).then(
						(response) => {
							if (response.status == 201) {
								this.employeeTips.push(this.editedItem);
								this.$store.dispatch(
									"alert/success",
									"Added Successfully"
								);
								this.getEmployeeTips();
								this.loading = false;
								this.close();
							} else {
								this.$store.dispatch(
									"alert/error",
									"Something Went Wrong!"
								);
								this.loading = false;
							}
						},
						(error) => {
							this.loading = false;
							if (error.response.status == 422) {
								this.$store.dispatch("alert/error", error);
							}
							if (error.response.status == 401) {
								this.handleLogout();
							}
							this.close();
							this.$store.dispatch(
								"alert/error",
								error.response.data.message
							);
						}
					);
				}
			}
		},
	},
};
</script>