<template>
  <v-row no-gutter class="ma-1">
    <v-col cols="12" md="12" class="d-flex justify-center">
      <v-card width="820" variant="flat" rounded="sm" class="pa-1">
        <template v-slot:title>
          <div class="mt-n7">
            <v-img
              height="100px"
              width="100px"
              class="rounded-circle mx-auto"
              src="@/assets/gratifyLogo.jpeg"
              alt="gratifyLogo"
            ></v-img>
            <div
              class="text-caption text-capitalize font-weight-black text-center mt-n6"
            ></div>
          </div>
          <div class="text-h1 font-weight-black text-center mt-1">
            <v-icon color="success">mdi-check-circle</v-icon>
          </div>
          <v-card>
            <div class="text-center mt-3">
              <v-card-text class="text-md-center">
                Tip received successfully
              </v-card-text>
              <v-btn color="secondary" class="mt-4" @click="tipAgain()">
                Tip again
              </v-btn>
            </div>
          </v-card>
        </template>
        <v-sheet max-width="820" class="mx-auto">
          <v-table>
            <tbody>
              <tr>
                <td>Transaction Code</td>
                <td>{{ payloadArray.mpesaReceiptNumber }}</td>
              </tr>
              <tr>
                <td>Employee Code</td>
                <td>{{ employeeCode }}</td>
              </tr>
              <tr>
                <td>Employee Name</td>
                <td>{{ payloadArray.employeeName }}</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>Ksh. {{ payloadArray.amount }}</td>
              </tr>
              <tr>
                <td>Service Fee</td>
                <td>Ksh. {{ payloadArray.commission }}</td>
              </tr>
              <tr>
                <td>Organization</td>
                <td>
                  {{ payloadArray.employeeOrg + " - " + employeeOrganization }}
                </td>
              </tr>
              <tr>
                <td>Comment</td>
                <td>{{ payloadArray.comment }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-sheet>
      </v-card>
    </v-col>
    <v-col> </v-col>
  </v-row>
</template>
  <script>
export default {
  name: "TipPaymentSuccess",
  data() {
    return {
      payloadArray: "",
      employeeCode: "",
      employeeOrganization: "",
    };
  },
  created() {
    this.payloadArray = JSON.parse(localStorage.getItem("payload"));
    if (Object.keys(this.payloadArray).length) {
      this.employeeCode = localStorage.employeeCode;
      this.employeeOrganization = localStorage.getItem("employeeOrganization");
    } else {
      this.$router.push("/tip?emp=" + this.employeeCode);
    }
  },

  methods: {
    tipAgain() {
      this.$router.push("/tip?emp=" + this.employeeCode);
    },
  },
};
</script>