<template>
  <v-row no-gutter class="ma-1">
    <v-col cols="12" md="12" class="d-flex justify-center">
      <v-card width="820" variant="flat" rounded="sm" class="pa-1">
        <template v-slot:title>
          <div class="mt-n7">
            <v-img
              height="100px"
              width="100px"
              class="rounded-circle mx-auto"
              src="@/assets/gratifyLogo.jpeg"
              alt="gratifyLogo"
            ></v-img>
            <div
              class="text-caption text-capitalize font-weight-black text-center mt-n6"
            ></div>
          </div>
          <div :class=" this.$vuetify.display.mobile ? 'text-h6 font-weight-black text-center mt-1 text-wrap' : 'text-h4 font-weight-black text-center mt-1 text-wrap'">
            Tip {{ person.firstname }} at
            {{
              parent_organisation.organisation_name +
              " " +
              organisation.organisation_name
            }}
          </div>
        </template>
        <v-sheet max-width="820" class="mx-auto">
          <v-form lazy-validation ref="form">
            <v-row class="mx-1">
              <v-col cols="12">
                <v-text-field
                  v-model="code_identity"
                  v-on:input="getPersonByEmpId(code_identity)"
                  placeholder="Employee Code"
                  :counter="10"
                  label="Enter Employee code"
                  variant="outlined"
                  :density="
                    this.$vuetify.display.mobile ? 'default' : 'compact'
                  "
                  required
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" md="2" class="mt-n2"> -->
              <v-col
                :cols="this.$vuetify.display.mobile ? mobile_country_code_cols : '12'"
                :md="this.$vuetify.display.mobile ? '6' : '2'"
                class="sm mt-n2"
              >
                <v-select
                  v-model="country_code"
                  :items="['254']"
                  variant="outlined"
                  density="compact"
                  readonly
                ></v-select>
              </v-col>
              <v-col
                :cols="this.$vuetify.display.mobile ? mobile_phone_number_cols : '12'"
                :md="this.$vuetify.display.mobile ? '6' : '10'"
                class="mt-n2"
              >
                <!-- <v-col :cols="this.$vuetify.display.mobile ? '8': '12'" md="6" class="mt-n2"> -->
                <v-text-field
                  v-model="phone_number"
                  placeholder="712100100"
                  :counter="10"
                  :rules="phoneRules"
                  label="Enter tipping phone number"
                  variant="outlined"
                  density="compact"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.amount"
                  :counter="10"
                  :rules="amountRules"
                  label="Enter tip amount"
                  variant="outlined"
                  :density="
                    this.$vuetify.display.mobile ? 'default' : 'compact'
                  "
                  required
                ></v-text-field>
                <!-- suggested amount -->
                <v-btn
                  @click="editedItem.amount = item.amount"
                  v-for="item in suggestedAmount"
                  :key="item.id"
                  class="mt-n2 mb-4"
                  color="grey"
                  variant="text"
                >
                  <span class="ml-n3">
                    {{ item.amount }}
                  </span>
                </v-btn>
                <div v-if="total" class="mt-n2 mb-2">
                  <v-btn
                    v-if="total"
                    class="mt-n2 mb-4"
                    color="grey"
                    variant="text"
                  >
                    <span class="ml-n3 text-body-2 font-weight-black">
                      {{ "Transaction Cost: Kshs. " + cost }}
                    </span>
                  </v-btn>

                  <v-btn
                    v-if="total"
                    class="mt-n2 mb-4"
                    color="grey"
                    variant="text"
                  >
                    <span class="ml-n3 text-body-2 font-weight-black">
                      {{ "Service Fee: Kshs. " + commission }}
                    </span>
                  </v-btn>

                  <v-btn
                    v-if="total"
                    class="mt-n2 mb-4"
                    color="grey"
                    variant="text"
                  >
                    <span class="ml-n3 text-body-2 font-weight-black">
                      {{ "Total Amount: Kshs. " + " " + total }}
                    </span>
                  </v-btn>
                </div>

                <!-- Ratings -->
                <v-card
                  class="mt-n2 mb-6 py-3"
                  variant="outlined"
                  color="grey-lighten-1"
                >
                  <template v-slot:subtitle>
                    <span class="text-black"> Rate </span>
                  </template>
                  <v-rating
                    v-model="editedItem.rating"
                    bg-color="orange-lighten-1"
                    color="yellow-accent-4"
                    class="mt-n5"
                    required
                  ></v-rating>
                </v-card>

                <!-- comment -->
                <v-textarea
                  class=""
                  v-model="comment1"
                  label="Comment"
                  rows="1"
                  variant="outlined"
                ></v-textarea>

                <!-- Suggested Comments -->
                <h4 class="mb-2">Suggested Comments</h4>
                <v-chip-group
                  v-model="comment2"
                  selected-class="text-yellow-darken-3"
                  mandatory
                >
                  <v-chip
                    v-for="comment in comments"
                    :key="comment.id"
                    :text="comment.comment"
                    :value="comment.comment"
                    variant="outlined"
                  ></v-chip>
                </v-chip-group>
                <!-- <v-select
                  v-model="editedItem.comment"
                  :items="comments"
                  item-title="comment"
                  item-value="comment"
                  label="Select Suggested Comments"
                  variant="outlined"
                  :density="
                    this.$vuetify.display.mobile ? 'default' : 'compact'
                  "
                >
                </v-select> -->
              </v-col>

              <!-- Anonymous -->
              <v-col cols="12" md="12" class="ml-n3 my-n10">
                <v-checkbox
                  label="Tip anonymously"
                  @change="toggleAnonymous"
                  required
                ></v-checkbox>
              </v-col>

              <!-- delivery -->
              <!-- <v-col cols="6" md="6" class="my-n10">
                <v-checkbox
                  v-model="checkbox"
                  label="Delivery"
                  required
                ></v-checkbox>
              </v-col> -->

              <!-- Optional Names -->
              <v-col v-if="anonymous === false" cols="12" md="6">
                <v-text-field
                  v-model="editedItem.firstname"
                  :counter="10"
                  label="Enter your name"
                  variant="outlined"
                  :density="
                    this.$vuetify.display.mobile ? 'default' : 'compact'
                  "
                ></v-text-field>
              </v-col>

              <!-- <v-col
                v-if="anonymous === false"
                :class="this.$vuetify.display.mobile ? 'mt-n2' : ''"
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="editedItem.lastname"
                  :counter="10"
                  label="Enter last name"
                  variant="outlined"
                  :density="
                    this.$vuetify.display.mobile ? 'default' : 'compact'
                  "
                ></v-text-field>
              </v-col> -->

              <!-- nickname -->
              <v-col
                v-if="anonymous"
                :class="this.$vuetify.display.mobile ? 'mt-n2' : 'mt-n3'"
                cols="12"
                md="12"
              >
                <!-- <v-text-field
                  :rules="nicknameRules"
                  v-model="editedItem.nickname"
                  :counter="10"
                  label="Enter nickname"
                  variant="outlined"
                  :density="
                    this.$vuetify.display.mobile ? 'default' : 'compact'
                  "
                ></v-text-field> -->
              </v-col>

              <!-- Submit Button -->
              <v-col cols="12" md="12" class="mb-5 mt-n8">
                <div class="d-flex flex-column">
                  <v-btn
                    color="secondary"
                    class="mt-4"
                    :block="this.$vuetify.display.mobile ? 'true' : 'false'"
                    @click="save"
                    prepend-icon="mdi-credit-card-outline"
                    :loading="loading"
                  >
                    Pay
                  </v-btn>
                </div>
              </v-col>

              <!-- T & C -->
              <v-col cols="12" md="12" class="mt-n10 mb-n7">
                <v-checkbox color="secondary" v-model="tandccheckbox">
                  <template v-slot:label>
                    <div>
                      I agree to
                      <v-tooltip location="bottom">
                        <template v-slot:activator="{ props }">
                          <a
                            target="_blank"
                            href="/terms-conditions"
                            v-bind="props"
                            @click.stop
                          >
                            terms and conditions
                          </a>
                        </template>
                        Opens in new window
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-title>Download our app</v-card-title>
        <v-card-text>
          <v-row>
            <a href="https://apps.apple.com/ke/app/gratify-tips/id6468884313"
              ><v-img
                width="150"
                height="100"
                alt="Download on the App Store"
                src="@/assets/app-store-badge.png"
              ></v-img
            ></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.theblueion.anno&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              ><v-img
                width="200"
                height="100"
                alt="Get it on Google Play"
                src="@/assets/google-play-store-badge.png"
              ></v-img
            ></a>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card prepend-icon="mdi-download" title="Download our App">
        <v-card-text class="text-sm-h6 py-2">
          You can install our official mobile
          <br />
          application from the following stores.
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <a href="https://apps.apple.com/ke/app/gratify-tips/id6468884313"
                ><v-img
                  width="150"
                  height="100"
                  alt="Download on the App Store"
                  src="@/assets/app-store-badge.png"
                ></v-img
              ></a>
            </v-col>
            <v-col>
              <a
                href="https://play.google.com/store/apps/details?id=com.theblueion.anno&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                ><v-img
                  width="200"
                  height="100"
                  alt="Get it on Google Play"
                  src="@/assets/google-play-store-badge.png"
                ></v-img
              ></a>
            </v-col>
          </v-row>
        </v-card-text>
        <template v-slot:actions>
          <v-btn
            class="ms-auto"
            text="Continue on the web"
            @click="dialog = false"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TipService from "../services/tip_service.js";
import { getMessaging, getToken } from "firebase/messaging";
import CommentService from "@/services/suggested_comments_service.js";
import TransactionCostService from "@/services/transaction_cost_service";
import CommissionCostService from "@/services/commission_cost_service";
// import { inject } from 'vue';

// const firebaseApp = inject('firebaseApp');
// const messaging = getMessaging(firebaseApp);

export default {
  data: () => ({
    name: "",
    loading: false,
    dialog: true,
    rating: 1,
    anonymous: false,
    valid: true,
    blockButton: false,
    TransactionCosts: [],
    CommissionCosts: [],
    cost: null,
    total: null,
    commission: null,
    person: {},
    device_token: "",
    organisation: {},
    parent_organisation: {},
    amountRules: [(v) => !!v || "amount is required"],
    phoneRules: [
      (v) => !!v || "phone number is required",
      (v) => (v && v.length <= 9) || "phone number is not valid",
    ],

    commentRules: [(v) => !!v || "comment is required"],
    nicknameRules: [(v) => !!v || "nickname is required"],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    suggestedAmount: [
      { id: 1, amount: "100" },
      { id: 2, amount: "150" },
      { id: 3, amount: "250" },
      { id: 4, amount: "300" },
    ],
    comments: [],
    tandccheckbox: false,
    suggestedComments: [
      // { id: 1, comment: "Great job" },
      // { id: 2, comment: "Well done" },
      // { id: 3, comment: "Keep up with the good work" },
      // { id: 4, comment: "Could use some improvements" },
      { id: 1, comment: "Excellent" },
      { id: 2, comment: "Good" },
      { id: 3, comment: "Fair" },
    ],
    editedItem: {
      qrcode: "",
      rating: 5,
      phone: "",
      amount: "",
      firstname: "",
      lastname: "",
      nickname: "",
      devicetoken: "",
      comment: "",
    },
    code_identity: "",
    country_code: "254",
    comment1: "",
    comment2: "",
    phone_number: "",
    mobile_country_code_cols: 4,
    mobile_phone_number_cols: 8
  }),

  watch: {
    "editedItem.amount": function (newAmount) {
      // transaction
      const amount = newAmount;
      const minCost = this.TransactionCosts.find(
        (cost) => amount >= cost.minimum
      );
      const maxCost = this.TransactionCosts.find(
        (cost) => amount <= cost.maximum
      );

      // commission
      const minCostCommision = this.CommissionCosts.find(
        (cost) => amount >= cost.minimum
      );
      const maxCostCommission = this.CommissionCosts.find(
        (cost) => amount <= cost.maximum
      );

      if (minCost && maxCost && minCostCommision && maxCostCommission) {
        this.cost = maxCost.cost;
        this.commission = maxCostCommission.amount;
        this.total =
          parseInt(amount) + parseInt(this.cost) + parseInt(this.commission);
      } else {
        this.cost = null;
        this.total = null;
        this.commission = null;
      }
    },
  },

  computed: {
    deviceToken() {
      return this.$store.state.device_token;
    },
  },

  mounted() {
    if(this.$vuetify.display.width <= 375) 
    {
      this.mobile_country_code_cols = 5
      this.mobile_phone_number_cols = 7
    }
  },
  created() {
    this.getEmployeeID();
    this.getPersonByEmpId(this.code_identity);
    this.getDeviceToken();
    this.getcomments();
    this.getTransactionCosts();
    this.getCommissionCosts();
  },

  methods: {
    async validate() {
      this.valid = await this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    async getDeviceToken() {
      try {
        const messaging = getMessaging();
        await Notification.requestPermission();

        this.device_token = await getToken(messaging, {
          vapidKey: process.env.VUE_APP_FCM_VAP_ID_KEY,
        });

        localStorage.setItem("deviceToken", this.device_token);
        this.$store.dispatch("notification/setDeviceToken", this.device_token);
      } catch (error) {
        console.error("Error getting FCM device token:", error);
      }
    },

    // anonymous
    toggleAnonymous() {
      this.anonymous = !this.anonymous;
    },

    formatPhoneNumber(phoneNumber) {
      const pattern = /^(0|254|\+254)([1-9]\d{8})$/;
      if (pattern.test(phoneNumber)) {
        if (phoneNumber.startsWith("0")) {
          return phoneNumber.slice(1);
        }
      } else {
        return phoneNumber;
      }
    },

    getEmployeeID() {
      const code_identity = this.$route.query.emp;
      if (code_identity) {
        this.code_identity = code_identity;
        localStorage.setItem('employeeCode', code_identity);
      }
    },
    getPersonByEmpId(EmpId) {
      if (EmpId.length < 4) {
        return true;
      }
      return TipService.getPersonByEmpId(EmpId).then(
        (response) => {
          if (response.status == 200) {
            this.person = response.data.data;
            this.organisation = this.person.organisation;
            this.parent_organisation = JSON.parse(
              JSON.stringify(this.person.organisation.parent_organisation)
            );

            localStorage.setItem("employeeOrganization", this.parent_organisation.organisation_name)
            console.log(this.parent_organisation.organisation_name);
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    getTransactionCosts() {
      return TransactionCostService.index().then(
        (response) => {
          if (response.status == 200) {
            this.TransactionCosts = response.data.data;

            this.loading = false;
          } else {
            this.TransactionCosts = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.TransactionCosts = [];
          if (error.response.status == 401) {
            this.handleLogout();
          } else {
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
          }
        }
      );
    },

    getCommissionCosts() {
      return CommissionCostService.index().then(
        (response) => {
          if (response.status == 200) {
            this.CommissionCosts = response.data.data;

            this.loading = false;
          } else {
            this.CommissionCosts = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.CommissionCosts = [];
          if (error.response.status == 401) {
            this.handleLogout();
          } else {
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
          }
        }
      );
    },

    getcomments() {
      return CommentService.index().then(
        (response) => {
          if (response.status == 200) {
            this.comments = response.data.data;
            this.loading = false;
          } else {
            this.comments = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.comments = [];
          if (error.response.status == 401) {
            this.handleLogout();
          } else {
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
          }
        }
      );
    },

    // make Payment
    async save() {
      this.editedItem.qrcode = this.code_identity;
      this.editedItem.devicetoken = this.device_token;
      if (this.editedItem.phone.length < 10) {
        this.editedItem.phone = this.country_code + this.phone_number;
      }
      if (this.comment1.length == 0) {
        this.editedItem.comment = this.comment2;
      } else {
        this.editedItem.comment = this.comment1;
      }
      if (this.anonymous) {
        this.editedItem.firstname = "";
      }
      console.log("this.editedItem", this.editedItem);
      // if (this.anonymous && this.editedItem.nickname.length < 1) {
      //   this.$store.dispatch("alert/error", "Please enter a nickname.");
      // } else {
      if (this.tandccheckbox) {
        await this.validate();
        if (this.valid.valid) {
          this.loading = true;
          return TipService.makePayment(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.loading = false;
                this.editedItem.phone = "";
                this.editedItem.phone = "";
              } else {
                this.$store.dispatch("alert/error", "Something Went Wrong!");
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              if (error.response.status == 422) {
                console.log(error);
                this.$store.dispatch("alert/error", error);
              }
              console.log(error.response);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.$store.dispatch(
          "alert/error",
          "Please agree to the terms and conditions."
        );
      }
      // }
    },
  },
};
</script>
<style scoped>
@media (max-width: 375px) {
}
</style>