<template>
  <div>
    <ErrorBoundary>
      <v-data-table
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="employees.data"
        :search="search"
        :custom-filter="filterText"
        :items-per-page="itemsPerPage"
        :items-length="totalItems"
        class="elevation-2"
        :loading="loading"
        @update:options="getOrgEmployees"
      >
        <template v-slot:top>
          <v-toolbar color="gray" flat>
            <v-toolbar-title
              class="text-h6 text-uppercase font-weight-bold text--darken-1 justify-center text-primary"
            >
              Employees
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ props }">
                <v-autocomplete
                  v-model="editedItem.organisation_id"
                  variant="outlined"
                  density="compact"
                  label="Filter by Organisation"
                  :items="organisations"
                  item-title="organisation_name"
                  item-value="id"
                  @update:modelValue="getOrgEmployees"
                  class="mt-5"
                ></v-autocomplete>
                <v-text-field
                  v-model="search"
                  density="compact"
                  variant="outlined"
                  label="Search"
                  append-inner-icon="mdi-magnify"
                  class="ma-3"
                  single-line
                  hide-details
                ></v-text-field>
                <v-btn
                  color="secondary"
                  dark
                  size="large"
                  prepend-icon="mdi-plus-thick"
                  variant="elevated"
                  v-bind="props"
                >
                  New Employee
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span
                    class="text-h6 font-weight-bold text--darken-1 justify-center text-primary"
                    >{{ formTitle }}</span
                  >
                </v-card-title>

                <v-card-text>
                  <v-form lazy-validation ref="form">
                    <v-container>
                      <v-row>
                        <v-col class="mt-n3" cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.firstname"
                            label="First Name"
                            variant="outlined"
                            density="compact"
                            :rules="rules.required"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-n3" cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.lastname"
                            label="Last Name"
                            variant="outlined"
                            density="compact"
                            :rules="rules.required"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-n3" cols="12" sm="12" md="12">
                          <v-text-field
                            v-if="is_admin"
                            v-model="editedItem.email"
                            label="Email"
                            variant="outlined"
                            density="compact"
                            :rules="rules.required"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-n3" cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.phone"
                            label="Phone Number"
                            variant="outlined"
                            density="compact"
                            :rules="rules.required"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-n3" cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-if="is_admin"
                            v-model="editedItem.role_category"
                            variant="outlined"
                            density="compact"
                            label="Select Role Category"
                            clearable
                            :items="roleCategoryType"
                            item-title="role_category"
                            item-value="id"
                            :rules="rules.required"
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="mt-n3" cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.code_identity"
                            label="Employee Id"
                            variant="outlined"
                            density="compact"
                            :rules="rules.required"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-n3" cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="selectedOrganisation"
                            variant="outlined"
                            disabled
                            label="Organisation"
                            :items="organisations"
                            item-title="organisation_name"
                            item-value="id"
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="mt-n3" cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-if="is_admin"
                            v-model="selectedRoleType"
                            variant="outlined"
                            density="compact"
                            label="Select Role Type"
                            :items="roleTypes"
                            item-title="description"
                            item-value="id"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" variant="elevated" @click="close">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="secondary"
                    variant="elevated"
                    @click="save"
                    :loading="loading"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- QRCODE DIALOG -->
            <v-dialog v-model="dialogQrcode" max-width="300">
              <v-card>
                <div class="ma-auto mt-6">
                  <EmployeeQrcode
                    :EmployeeCode="removeSpaces(editedItem.code_identity)"
                  />
                </div>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="error"
                    variant="elevated"
                    @click="dialogQrcode = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Rating Dialog -->
            <v-dialog
              v-model="dialogRating"
              content-class="my-custom-dialog"
              max-width="550"
            >
              <v-card :loading="loading">
                <template v-slot:title>
                  <span style="display: flex; justify-content: flex-start">{{
                    editedItem.employee_name + " " + "reviews"
                  }}</span>
                  <span style="display: flex; justify-content: flex-end">
                    <v-icon
                      color="primarysecondary"
                      class="mt-n8"
                      size="30"
                      icon="mdi-close"
                      @click="dialogRating = false"
                    ></v-icon>
                  </span>

                  <!-- Average Rating -->
                  <v-card variant="flat">
                    <template v-slot:title>
                      <span class="text-h2">
                        {{ convertToFloat(editedItem.average_rating) }}
                      </span>
                      <div>
                        <v-rating
                          hover
                          readonly
                          :length="5"
                          :size="17"
                          class="mt-n2 ml-2"
                          :model-value="editedItem.average_rating"
                          half-increments
                          variant="compact"
                          active-color="amber"
                        />
                      </div>
                    </template>
                    <template v-slot:subtitle>
                      <span class="ml-2">
                        {{ editedItem.number_of_ratings + " " + "review(s)" }}
                      </span>
                    </template>
                  </v-card>
                </template>
                <v-card-text>
                  <v-list lines="two">
                    <v-list-item
                      v-for="(tip, i) in tips"
                      :key="i"
                      :subtitle="
                        tip.comment
                          ? 'Comment:' + ' ' + tip.comment
                          : 'No comments'
                      "
                    >
                      <template v-slot:prepend>
                        <v-avatar color="secondary">
                          <v-icon
                            icon-color="white"
                            icon="mdi-account"
                          ></v-icon>
                        </v-avatar>
                      </template>
                      <template v-slot:title>
                        <v-row>
                          <v-col v-if="is_admin" cols="7">
                            <p>
                              {{
                                "Tipped " +
                                getAmountWithCurrencyOrZero(tip.amount) +
                                " by " +
                                getFullNameOrNickname(tip.tipper)
                              }}
                              <br />
                              {{
                                "at " +
                                formatDate(tip.mpesa_results.transaction_date)
                              }}
                            </p>
                          </v-col>
                          <v-col v-else cols="7">
                            <p>
                              {{
                                "Tipped" +
                                " " +
                                "by" +
                                " " +
                                getFullNameOrNickname(tip.tipper)
                              }}
                              <br />
                              {{
                                "at " +
                                formatDate(tip.mpesa_results.transaction_date)
                              }}
                            </p>
                          </v-col>
                          <v-col cols="5">
                            <v-rating
                              hover
                              readonly
                              :length="5"
                              :size="14"
                              class="mt-n2 ml-15"
                              :model-value="tip.rating"
                              half-increments
                              variant="compact"
                              active-color="amber"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12"> </v-col>
                        </v-row>
                      </template>
                      <v-divider class="my-1"></v-divider>
                    </v-list-item>
                  </v-list>
                  <v-pagination
                    v-model="tipsPage"
                    :length="tipsTotalPages"
                    @click="onPageChange"
                    class="mt-4"
                    color="primary"
                    rounded="circle"
                  ></v-pagination>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    class="mr-3"
                    variant="elevated"
                    @click="dialogRating = false"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- here end of rating dialog -->
          </v-toolbar>
        </template>
        <template v-slot:[`item.average_rating`]="{ item }">
          <div class="text-error" v-if="item.average_rating == 0">
            {{ "No Rating" }}
          </div>
          <div @click="showRating(item)" v-else>
            <v-tooltip
              text="click to view ratings"
              activator="parent"
              location="bottom"
            >
              <template v-slot:activator="{ tooltip }">
                <v-rating
                  hover
                  v-bind="tooltip"
                  readonly
                  :length="5"
                  :size="21"
                  :model-value="item.average_rating"
                  active-color="amber"
                />
              </template>
            </v-tooltip>
          </div>
        </template>
        <!-- <template v-slot:[`item.id`]="{ item }">
           {{  item.id }}
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="success"
            size="small"
            class="me-3"
            @click="showQrcode(item)"
          >
            mdi-eye
          </v-icon>

          <v-icon
            color="primary"
            size="small"
            class="me-3"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon color="error" size="small" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data> No data Available </template>
        <template v-slot:bottom>
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="getOrgEmployees"
            ></v-pagination>
          </div>
        </template>
      </v-data-table>
    </ErrorBoundary>
  </div>
</template>

<script>
import EmployeesService from "@/services/employees.service";
import EmployeeQrcode from "@/components/QRCodeComponent";
import OrganisationService from "@/services/organisation_service";
import RoleCategoryTypeService from "@/services/role_category_types_service";
import UserService from "@/services/user.service";
import RoleTypeService from "@/services/role_type_service";
import ErrorBoundary from "@/components/ErrorBoundary.vue";
import authHeader from "@/services/auth-header";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    EmployeeQrcode,
    ErrorBoundary,
  },

  data: () => ({
    is_admin: false,
    dialog: false,
    dialogDelete: false,
    dialogQrcode: false,
    dialogRating: false,
    valid: false,
    roleTypes: [],
    search: "",
    path: "",
    itemsPerPage: 10,
    totalItems: 0,
    numberOfPages: 0,
    page: 1,
    tipsPage: 1,
    tipsTotalPages: 1,
    employees: { data: [] },
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    roleCategoryType: [],
    organisations: [],
    employeeTips: [],
    tips: [],
    users: [],
    orgName: "",
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "At least one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
    },
    headers: [
      { title: "Name", value: "name" },
      {
        title: "Organisation",
        value:
          "person.party.party_roles[0].party_relation_ship_to[0].employer.party.organisation.organisation_name",
      },
      {
        title: "Employee Id",
        value:
          "person.party.party_roles[0].party_relation_ship_to[0].code_identity",
        filterable: true,
      },
      {
        title: "Rating",
        value: "average_rating",
        align: "center",
        filterble: true,
      },
      {
        title: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],

    loading: true,
    editedIndex: -1,
    editedItem: {
      organisation_name: "",
      code_identity: "",
      role_category: "",
      organisationId: "",
      role_type: "",
      firstname: "",
      lastname: "",
      phone: "",
      // email: "",
    },
    defaultItem: {
      organisation_name: "",
      code_identity: "",
      organisationId: "",
      firstname: "",
      role_category: "",
      role_type: "",
      lastname: "",
      phone: "",
      // email: "",
    },

    reviewItem: {
      organisation_name: "",
      code_identity: "",
      role_category: "",
      organisationId: "",
      firstname: "",
      lastname: "",
      phone: "",
      // email: "",
    },

    items: [
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="text-primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
      },
      { type: "divider", inset: true },
      {
        prependAvatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Summer BBQ",
        subtitle: `<span class="text-primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Employee" : "Edit Employee";
    },
    user() {
      return this.$store.state.auth.user;
    },
    userCompany() {
      const storedCompany = localStorage.getItem("company");
      return storedCompany ? JSON.parse(storedCompany) : null;
    },
    orgId() {
      return localStorage.getItem("orgId");
    },
    pageCount() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    selectedRoleType: {
      get() {
        return (
          this.roleTypes.find(
            (role) => role.description.toLowerCase() === "employee"
          ) || null
        );
      },

      set(value) {
        this.editedItem.role_type = value ? value.id : null;
      },
    },

    selectedOrganisation: {
      get() {
        return this.organisations.find((org) => org.id == this.orgId) || null;
      },

      set(value) {
        // console.log('idddd', value)
        this.editedItem.organisation_name = value ? value.id : null;
      },
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
    page(newPage) {
      this.page = newPage;
      this.getOrgEmployees({
        page: newPage,
        itemsPerPage: this.itemsPerPage,
      });
    },
  },

  created() {
    this.getOrganisations();
    this.getRoleCategoryTypes();
    this.getOrgEmployees();
    this.getRoleTypes();
    this.editedItem.organisation_name = this.orgId;
    this.checkRole();
  },

  methods: {
    checkRole() {
      localStorage.getItem("role_category") == "administrator"
        ? (this.is_admin = true)
        : (this.is_admin = false);
    },
    async validate() {
      this.valid = await this.$refs.form.validate();
    },
    convertToFloat(value) {
      return isNaN((value = Number(value))) ? NaN : value.toFixed(1);
    },

    removeSpaces(codeIdentity) {
      return codeIdentity.replace(/\s/g, "");
    },

    formatPhoneNumber(phoneNumber) {
      const pattern = /^(0|254|\+254)([1-9]\d{8})$/;
      if (pattern.test(phoneNumber)) {
        if (phoneNumber.startsWith("0")) {
          return "+254" + phoneNumber.slice(1);
        } else {
          return "+254" + phoneNumber.slice(-9);
        }
      } else if (/^[71]/.test(phoneNumber)) {
        return "+254" + phoneNumber;
      } else {
        return phoneNumber;
      }
    },

    formatDate(date) {
      return moment(date).format("HH:MM DD-MMM-YYYY");
    },

    // search
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toLowerCase().includes(search.toLowerCase())
      );
    },

    async getOrgEmployees() {
      this.loading = true;
      try {
        const response = await EmployeesService.index(
          this.orgId ? this.orgId : this.userCompany.id,
          this.page,
          this.itemsPerPage
        );

        if (response.status === 200) {
          this.employees = response.data.data;
          this.employees.current_page = this.page;
          this.totalItems = this.employees.total;
          this.numberOfPages = this.employees.itemsPerPage;

          //
          this.loading = false;
        } else {
          this.employees = [];
          this.loading = false;
          this.$store.dispatch("alert/error", response.data.console.error);
          console.error(response.data.console.error);
        }
      } catch (error) {
        this.employees = [];
        this.loading = false;
        if (error.response && error.response.status === 401) {
          this.handleLogout();
        }
        console.error(error);
        this.$store.dispatch("alert/error", error.message);
      }
    },

    getEmployeeTips(item) {
      this.editedItem.code_identity =
        item.person.party.party_roles[0].party_relation_ship_to[0].code_identity;
      return EmployeesService.getEmployeeTips(
        this.editedItem.code_identity,
        this.tipsPage
      )
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.employeeTips = response.data.data;
            this.tips = this.employeeTips.tips.data;
            // console.log("tipsss", this.employeeTips.tips);
            this.tipsPage = this.employeeTips.tips.current_page;
            this.tipsTotalPages = this.employeeTips.tips.last_page;
            this.path = this.employeeTips.tips.path;
            //
            this.loading = false;
          } else {
            this.employeeTips = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        })
        .catch((error) => {
          this.employeeTips = [];
          if (error.response && error.response.status === 401) {
            this.handleLogout();
          }
          console.error(error);
          this.$store.dispatch("alert/error", error.message);
        });
    },

    onPageChange() {
      const url = `${this.path}?page=${this.tipsPage}`;
      axios
        .get(url, { headers: authHeader() })
        .then((response) => {
          this.employeeTips = response.data.data.tips.data;
          this.totalPages = response.data.data.tips.last_page;
          this.tips = this.employeeTips;
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch("alert/error", error.message);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        });
    },

    // get all organisations
    getOrganisations() {
      if (this.user) {
        return OrganisationService.index().then(
          (response) => {
            if (response.status == 200) {
              this.organisations = response.data.data;
              this.loading = false;
            } else {
              this.organisations = [];
              this.loading = false;
              this.$store.dispatch("alert/error", response.data.console.error);
              console.log(response.data.console.error);
            }
          },
          (error) => {
            this.organisations = [];
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      } else {
        this.handleLogout();
      }
    },

    getUser(user) {
      return UserService.getUser(user).then(
        (response) => {
          if (response.status == 200) {
            this.users = response.data.data;
            this.loading = false;
          } else {
            this.users = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.users = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    // get role category types
    getRoleCategoryTypes() {
      if (this.user) {
        return RoleCategoryTypeService.index().then(
          (response) => {
            if (response.status == 200) {
              this.roleCategoryType = response.data.data;
              this.loading = false;
            } else {
              this.roleCategoryType = [];
              this.loading = false;
              this.$store.dispatch("alert/error", response.data.console.error);
              console.log(response.data.console.error);
            }
          },
          (error) => {
            this.roleCategoryType = [];
            if (error.response.status == 401) {
              this.handleLogout();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
          }
        );
      } else {
        this.handleLogout();
      }
    },

    // Data Manipulation
    deleteItem(item) {
      this.loading = true;
      this.editedIndex = this.employees.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem.id);
      this.employees.data.splice(this.editedIndex, 1);
      EmployeesService.delete(this.editedItem.person.id).then(
        (response) => {
          if (response.status == 200) {
            this.loading = false;
            this.close();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
            this.loading = false;
          }
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
          this.loading = false;
        }
      );
    },

    close() {
      setTimeout(() => {
        this.dialog = false;
      }, 2000);
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.loading = false;
      });
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    async editItem(item) {
      this.editedIndex = this.employees.data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.editedItem.organisation_name =
        item.person.party.party_roles[0].party_relation_ship_to[0].employer.party.organisation.id;
      this.editedItem.code_identity =
        item.person.party.party_roles[0].party_relation_ship_to[0].code_identity;
      this.editedItem.role_category = item.role_category_type
        ? item.role_category_type[0].id
        : null;

      this.editedItem.organisationId =
        item.person.party.party_roles[0].party_relation_ship_to[0].employer.party.organisation.id;
      this.editedItem.role_type = "";
      this.editedItem.firstname = item.person.firstname;
      this.editedItem.lastname = item.person.lastname;
      this.editedItem.phone =
        item.person.phone_numbers.length > 0
          ? item.person.phone_numbers[0].number
          : null;
      // this.editedItem.email = item.email;
      this.dialog = true;
    },

    getRoleTypes() {
      return RoleTypeService.index().then(
        (response) => {
          if (response.status == 200) {
            this.roleTypes = response.data.data;

            this.loading = false;
          } else {
            this.roleTypes = [];
            this.loading = false;
            this.$store.dispatch("alert/error", response.data.console.error);
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.roleTypes = [];
          if (error.response.status == 401) {
            this.handleLogout();
          } else {
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
          }
        }
      );
    },

    showQrcode(item) {
      this.editedIndex = this.employees.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.code_identity =
        item.person.party.party_roles[0].party_relation_ship_to[0].code_identity;
      this.dialogQrcode = true;
    },

    getFullNameOrNickname(person) {
      const firstName = person.firstname || "";
      const lastName = person.lastname || "";
      const nickname = person.nickname || "tipper";
      if (firstName.trim() !== "" && lastName.trim() !== "") {
        return `${firstName} ${lastName}`;
      } else {
        return nickname;
      }
    },

    getAmountWithCurrencyOrZero(amount) {
      const amount1 = amount !== null ? amount : 0.0;
      const formattedAmount = amount1.toFixed(2);
      return `Ksh. ${formattedAmount}`;
    },

    async showRating(item) {
      this.loading = false;
      await this.getEmployeeTips(item);
      this.editedIndex = this.employees.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.employee_name = item.name;
      this.dialogRating = true;
      this.editedItem.number_of_ratings = this.tips.length;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getOrganizationName(item) {
      this.organisations.map((organization) => {
        if (
          organization.id ==
          item.person.party.party_roles[0].party_relation_ship_type_to[0]
            .party_relation_ships[0].employer.id
        ) {
          // console.log("orgName", organization.organisation_name);
          return organization.organisation_name;
        } else {
          return "N/A";
        }
      });
    },

    async save() {
      await this.validate();
      this.editedItem.organisationId = this.editedItem.organisation_name;
      if (this.is_admin) {
        this.editedItem.role_type = this.selectedRoleType.id;
      } else {
        this.editedItem.role_type = this.roleTypes.find(
          (element) => element.description == "Employee"
        ).id;

        this.editedItem.role_category = this.roleCategoryType.find(
          (element) => element.role_category == "Employee"
        ).id;
      }
      this.editedItem.phone = this.formatPhoneNumber(this.editedItem.phone);
      if (this.valid || this.valid.valid) {
        this.loading = true;
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.employees.data[this.editedIndex], this.editedItem);
          EmployeesService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.loading = false;
                this.getOrgEmployees();
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", "Something Went Wrong!");
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              console.log(error);

              if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error);
              }
              if (error.response.status == 401) {
                this.handleLogout();
              }
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // Create
          EmployeesService.store(this.editedItem).then(
            (response) => {
              if (response.status == 201) {
                this.employees.data.push(this.editedItem);
                this.$store.dispatch("alert/success", "Added Successfully");
                this.getOrgEmployees();
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", "Something Went Wrong!");
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              console.log(error);

              if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error);
              }
              if (error.response.status == 401) {
                this.handleLogout();
              }
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.my-custom-dialog {
  right: 0;
  top: 0;
}
</style>