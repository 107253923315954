<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="employees"
			:search="search"
			:custom-filter="filterText"
			class="elevation-2"
			:loading="loading"
		>
			<template v-slot:top>
				<v-toolbar color="gray" flat>
					<v-toolbar-title
						class="text-h6 text-uppercase font-weight-bold text--darken-1 justify-center text-primary"
					>
						Ratings
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-dialog v-model="dialog" max-width="500px">
						<template v-slot:activator="{ props }">
							<v-text-field
								v-model="search"
								density="compact"
								variant="outlined"
								label="Search"
								append-inner-icon="mdi-magnify"
								class="ma-3"
								single-line
								hide-details
							></v-text-field>
							<v-btn
								color="secondary"
								dark
								size="large"
								prepend-icon="mdi-plus-thick"
								variant="elevated"
								v-bind="props"
							>
								New Ratings
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span
									class="text-h6 font-weight-bold text--darken-1 justify-center text-primary"
									>{{ formTitle }}</span
								>
							</v-card-title>

							<v-card-text>
								<v-form lazy-validation ref="form">
									<v-container>
										<v-row>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-text-field
													v-model="
														editedItem.firstname
													"
													label="First Name"
													variant="outlined"
													density="compact"
													:rules="rules.required"
												></v-text-field>
											</v-col>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-text-field
													v-model="
														editedItem.lastname
													"
													label="Last Name"
													variant="outlined"
													density="compact"
													:rules="rules.required"
												></v-text-field>
											</v-col>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-text-field
													v-model="editedItem.email"
													label="Email"
													variant="outlined"
													density="compact"
													:rules="rules.required"
												></v-text-field>
											</v-col>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-text-field
													v-model="editedItem.phone"
													label="Phone Number"
													variant="outlined"
													density="compact"
													:rules="rules.required"
												></v-text-field>
											</v-col>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-autocomplete
													v-model="
														editedItem.role_category
													"
													variant="outlined"
													density="compact"
													label="Select Role Category"
													clearable
													:items="roleCategoryType"
													item-title="role_category"
													item-value="id"
												></v-autocomplete>
											</v-col>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-text-field
													v-model="
														editedItem.code_identity
													"
													label="Employee Id"
													variant="outlined"
													density="compact"
													:rules="rules.required"
												></v-text-field>
											</v-col>
											<v-col
												class="mt-n3"
												cols="12"
												sm="12"
												md="12"
											>
												<v-autocomplete
													v-model="
														editedItem.organisation_name
													"
													variant="outlined"
													density="compact"
													label="Select Organisation"
													clearable
													:items="organisations"
													item-title="organisation_name"
													item-value="id"
												></v-autocomplete>
											</v-col>
										</v-row>
									</v-container>
								</v-form>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="error"
									variant="elevated"
									@click="close"
								>
									Cancel
								</v-btn>
								<v-btn
									color="primary"
									variant="elevated"
									@click="save"
									:loading="loading"
								>
									Save
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="text-h5"
								>Are you sure you want to delete this
								item?</v-card-title
							>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="blue-darken-1"
									variant="text"
									@click="closeDelete"
									>Cancel</v-btn
								>
								<v-btn
									color="blue-darken-1"
									variant="text"
									@click="deleteItemConfirm"
									>OK</v-btn
								>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<!-- QRCODE DIALOG -->
					<v-dialog v-model="dialogQrcode" max-width="300">
						<v-card>
							<div class="ma-auto mt-6">
								<EmployeeQrcode
									:EmployeeCode="editedItem.code_identity"
								/>
							</div>
							<v-card-actions>
								<v-spacer></v-spacer>

								<v-btn
									color="error"
									variant="elevated"
									@click="dialogQrcode = false"
								>
									Cancel
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon
					color="success"
					size="small"
					class="me-3"
					@click="showQrcode(item)"
				>
					mdi-eye
				</v-icon>

				<!-- <v-icon
					color="primary"
					size="small"
					class="me-3"
					@click="editItem(item)"
				>
					mdi-pencil
				</v-icon>
				<v-icon color="error" size="small" @click="deleteItem(item)">
					mdi-delete
				</v-icon> -->
			</template>
			<template v-slot:no-data> No data Available </template>
		</v-data-table>
		<AlertComponent />
	</div>
</template>

<script>

import EmployeesService from "@/services/employees.service";
import EmployeeQrcode from "@/components/QRCodeComponent";
import OrganisationService from "@/services/organisation_service";
import RoleCategoryTypeService from "@/services/role_category_types_service";
import UserService from "@/services/user.service";

export default {
	components: {
		
		EmployeeQrcode,
	},
	data: () => ({
		dialog: false,
		dialogDelete: false,
		dialogQrcode: false,
		valid: false,
		search: "",
		employees: [],
		roleCategoryType: [],
		organisations: [],
		users: [],
		rules: {
			select: [(v) => !!v || "An item should be selected"],
			select2: [
				(v) => v.length > 0 || "At least one item should be selected",
			],
			required: [
				(v) => !!v || "Field is required",
				(v) => (v && v.length >= 3) || "Min 3 characters",
			],
		},
		headers: [
			{ title: "Name", value: "employee_name" },
			{ title: "Organisation", value: "organisation_name" },
			{ title: "Employee Id", value: "code_identity", filterable: true },
			{
				title: "Actions",
				value: "actions",
				sortable: false,
				align: "center",
			},
		],

		loading: true,
		editedIndex: -1,
		editedItem: {
			organisation_name: "",
			code_identity: "",
			role_category: "",
			organisationId: "",
			firstname: "",
			lastname: "",
			phone: "",
			email: "",
		},
		
		defaultItem: {
			organisation_name: "",
			code_identity: "",
			organisationId: "",
			firstname: "",
			role_category: "",
			lastname: "",
			phone: "",
			email: "",
		},
	}),

	computed: {
		formTitle() {
			return this.editedIndex === -1 ? "New Employee" : "Edit Employee";
		},
		user() {
			return this.$store.state.auth.user;
		},
	},

	watch: {
		dialog(val) {
			val || this.close();
		},

		dialogDelete(val) {
			val || this.closeDelete();
		},
	},

	created() {
		this.getOrgEmployees();
		this.getOrganisations();
		this.getRoleCategoryTypes();
	},

	methods: {
		async validate() {
			this.valid = await this.$refs.form.validate();
		},

		// search
		filterText(value, search) {
			return (
				value != null &&
				search != null &&
				typeof value === "string" &&
				value.toLowerCase().includes(search.toLowerCase())
			);
		},

		getOrgEmployees() {
			// console.log("te usr", this.user.person.organisation[0].id);
			return EmployeesService.index(
				this.user.person.organisation[0].id
			).then(
				(response) => {
					if (response.status == 200) {
						this.employees = response.data.data;

						this.loading = false;
					} else {
						this.employees = [];
						this.loading = false;
						this.$store.dispatch(
							"alert/error",
							response.data.console.error
						);
						console.log(response.data.console.error);
					}
				},
				(error) => {
					this.employees = [];
					console.log(error);
					this.$store.dispatch("alert/error", error.message);
					if (error.response.status == 401) {
						this.handleLogout();
					}
				}
			);
		},

		// get all organisations
		getOrganisations() {
			return OrganisationService.index(
				this.user.person.organisation[0].id
			).then(
				(response) => {
					if (response.status == 200) {
						this.organisations = response.data.data;
						this.loading = false;
					} else {
						this.organisations = [];
						this.loading = false;
						this.$store.dispatch(
							"alert/error",
							response.data.console.error
						);
						console.log(response.data.console.error);
					}
				},
				(error) => {
					this.organisations = [];
					console.log(error);
					this.$store.dispatch("alert/error", error.message);
					if (error.response.status == 401) {
						this.handleLogout();
					}
				}
			);
		},

		getUser(user) {
			return UserService.getUser(user).then(
				(response) => {
					if (response.status == 200) {
						this.users = response.data.data;
						this.loading = false;
					} else {
						this.users = [];
						this.loading = false;
						console.log(response.data.console.error);
					}
				},
				(error) => {
					this.users = [];
					console.log(error);
					if (error.response.status == 401) {
						this.handleLogout();
					}
				}
			);
		},

		// get role category types
		getRoleCategoryTypes() {
			return RoleCategoryTypeService.index(
				this.user.person.organisation[0].id
			).then(
				(response) => {
					if (response.status == 200) {
						this.roleCategoryType = response.data.data;
						this.loading = false;
					} else {
						this.roleCategoryType = [];
						this.loading = false;
						this.$store.dispatch(
							"alert/error",
							response.data.console.error
						);
						console.log(response.data.console.error);
					}
				},
				(error) => {
					this.roleCategoryType = [];
					if (error.response.status == 401) {
						this.handleLogout();
					}
					console.log(error);
					this.$store.dispatch("alert/error", error.message);
				}
			);
		},

		// Data Manipulation
		deleteItem(item) {
			this.loading = true;
			this.editedIndex = this.employees.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.employees.splice(this.editedIndex, 1);
			EmployeesService.delete(this.editedItem).then(
				(response) => {
					if (response.status == 200) {

						this.loading = false;
						this.close();
					} else {
						this.$store.dispatch(
							"alert/error",
							response.data.message
						);
					}
				},
				(error) => {
					if (error.response.status == 422) {
						this.$store.dispatch(
							"alert/error",
							error.response.data.message
						);
						this.loading = false;
					}
					if (error.response.status == 401) {
						this.handleLogout();
					}
					console.log(error);
					this.$store.dispatch(
						"alert/error",
						error.response.data.message
					);
					this.loading = false;
				}
			);
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				this.loading = false;
			});
		},

		handleLogout() {
			this.$store.commit('logout');
			this.$store.dispatch('resetInactivityTimeout');
			this.$store.dispatch("auth/logout");
			this.$store.dispatch('resetInactivityTimeout');
			this.$router.push("/login");
			this.$store.dispatch("alert/success", 'Logout Successfully');
		},

		editItem(item) {
			this.getUser(item);
			this.editedIndex = this.employees.indexOf(item);
			this.editedItem = Object.assign({}, item);
			(this.editedItem.phone = this.users.person.phoneNumbers[0]
				? this.users.person.phoneNumbers[0].country_code + this.users.person.phoneNumbers[0].number : ""),
				(this.editedItem.email = this.users.email),
				(this.editedItem.role_category = this.users.roleCategory[0].id),
				(this.dialog = true);
		},

		showQrcode(item) {
			this.editedIndex = this.employees.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogQrcode = true;
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			this.validate();
			this.editedItem.organisationId = this.editedItem.organisation_name;
			if (this.valid || this.valid.valid) {
				this.loading = true;
				// edit
				if (this.editedIndex > -1) {
					Object.assign(
						this.employees[this.editedIndex],
						this.editedItem
					);
					EmployeesService.update(this.editedItem).then(
						(response) => {
							if (response.status == 200) {
								this.$store.dispatch(
									"alert/success",
									response.data.message
								);
								this.loading = false;
								this.getOrgEmployees();
								this.close();
							} else {
								this.$store.dispatch(
									"alert/error",
									"Invalid data, please check the form try again!"
								);
								this.loading = false;
							}
							(error) => {
								this.getOrgEmployees();
								if (error.response.status == 422) {
									this.$store.dispatch(
										"alert/error",
										error.response.data.message
									);
								}
								if (error.response.status == 401) {
									this.handleLogout();
								}
								this.$store.dispatch(
									"alert/error",
									error.response.data.message
								);
								this.loading = false;
							};
						}
					);
				} else {
					// Create
					EmployeesService.store(this.editedItem).then(
						(response) => {
							if (response.status == 201) {
								this.employees.push(this.editedItem);
								this.$store.dispatch(
									"alert/success",
									"Added Successfully"
								);
								this.getOrgEmployees();
								this.loading = false;
								this.close();
							} else {
								this.$store.dispatch(
									"alert/error",
									"Something Went Wrong!"
								);
								this.loading = false;
							}
						},
						(error) => {
							this.loading = false;
							if (error.response.status == 422) {
								this.$store.dispatch("alert/error", error);
							}
							if (error.response.status == 401) {
								this.handleLogout();
							}
							this.close();
							this.$store.dispatch(
								"alert/error",
								error.response.data.message
							);
						}
					);
				}
			}
		},
	},
};
</script>